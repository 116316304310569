<template>
<div>

  <div class="col-lg-9 col-md-9 col-sm-12">
  <div class="blog blog-style-default">
<article v-for="news in newsitems" :id="`post-${news.id}`" :class="`rounded-corners default-background post-${news.id} post type-post status-publish format-standard has-post-thumbnail hentry category-announcements category-events tag-115 tag-128 tag-114 tag-106`">
  <div class="item-post-container">
<div class="item-post clearfix">


  <div class="post-image">

                    <router-link :to="{ name: 'newscontent', params: {  contentdata: news, id : news.id }}" class="default"><img width="540" height="390" :src="news.imagesrcmedium" class="img-responsive wp-post-image" alt="" /></router-link>


  </div>
  <div class="post-text">
    <header class="entry-header">

      <div class="entry-title"><router-link :to="{ name: 'newscontent', params: {  contentdata: news, id : news.id }}" rel="bookmark"><div v-html="`${news.title.rendered}`"> </div> </router-link></div>
      <div class="entry-meta">
                        <taglinks :categories="news.categoriesarray"/>
                    </div>
      <!-- .entry-meta -->
    </header>
    <!-- .entry-header -->

                <div class="entry-content">
                            <div v-html="news.excerpt.rendered"></div>
                </div>

    <div class="clearfix entry-info">
                    <span class="entry-date">{{news.postdateformated}}</span>																			<span class="read-more-link"><router-link :to="{ name: 'newscontent', params: {  contentdata: news, id : news.id }}"> Далее</router-link></span>
                </div>
  </div>
</div>
</div>
</article><!-- #post-12118 -->


</div>

</div>

</div>
</template>

<script>
import axios from "axios"
import "datejs"
import {getwpposts} from "@/lib/utils.js"
import categories from "@/lib/categories.js"
import taglinks from "@/components/taglinks.vue"
export default {
components : {taglinks},
name : "newsblockAnonce",
props : [
'category','count'
    ],

data() {

return {

newsitems : [],
onpagecount : 6
 }

},
mounted() {

this.getnews()

},
methods : {

async getnews()  {

//console.log("Getting news")


this.newsitems = await getwpposts(categories.anonce,this.onpagecount)


    }




}

}

</script>
