<template>


    <div id="main" class="site-main">

    <div id="main-content" class="main-content">

    <div id="page-title" class="page-title-block page-title-style-1 " style=""><div class="container"><div class="page-title-title"><h1 style="">  Анонсы</h1></div></div></div>
    	<div class="block-content">
    		<div class="container">
    			<div class="panel row panel-sidebar-position-right with-sidebar">

<newsblockAnonce count=4 />

    				<div class="sidebar col-lg-3 col-md-3 col-sm-12" role="complementary"><div class="widget-area">
<searchbar />

      <calendar />

<rubricator position="anonce"/>

<tagscloud/>
  </div>
    </div><!-- .sidebar -->			</div>
    		</div><!-- .container -->
    	</div><!-- .block-content -->
    </div><!-- #main-content -->

    <!-- Your Google Analytics Plugin is missing the tracking ID -->

    		</div><!-- #main -->


</template>

<script>
import tagscloud from "@/components/tagscloud.vue"
import newsblockAnonce from "@/components/news/newsblock-anonce.vue"
import rubricator from "@/components/rubricator.vue"
import searchbar from "@/components/searchbar.vue"
import calendar from "@/components/calendar.vue"
import {rollup} from "@/lib/utils.js"

export default {
name : 'News_anonce',
components : {tagscloud, newsblockAnonce,rubricator,searchbar, calendar},
mounted() {

  rollup()
}


}


</script>
